import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
import { StaticImage } from "gatsby-plugin-image";
import { GenericPage } from "../../components/mdx/page";
import { Accordion } from "../../components/widgets/accordion";
import { HelpPopup } from "../../components/widgets/help-popup";
import { Logo } from "../../components/widgets/tt-branding";
import { ContentPlusDecor } from "../../components/widgets/content-plus-decor";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = GenericPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1>{`Company`}</h1>
    <ContentPlusDecor decor={<Logo mdxType="Logo" />} mdxType="ContentPlusDecor">
      <h2>{`Work With Us`}</h2>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/company/writers/"
          }}>{`Quant Developers & Writers`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/company/referral-program/"
          }}>{`Referral Program`}</a></li>
      </ul>
      <h2>{`Legal`}</h2>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/company/disclaimer/"
          }}>{`Disclaimer`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/company/terms-of-use/"
          }}>{`Terms of Use`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/company/privacy-policy/"
          }}>{`Privacy Policy`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/company/sub-processors/"
          }}>{`Sub Processors`}</a></li>
      </ul>
    </ContentPlusDecor>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      